<template>
  <a-empty :image="simpleImage"  class="notice-empty" v-if="dataSourceList.length < 1"/>
  <a-collapse v-model:activeKey="activeKey" :bordered="false" :ghost="true" style="background-color: #ffffff" v-else>
    <a-collapse-panel v-for="(item) in dataSourceList" :key="item.id"
                      :collapsible="item.childrenStaffNotices?.length>0 ? 'disabled':''">
      <template #header>
        <a-list item-layout="horizontal" :data-source="[item]" id="notice-list">
          <template #renderItem="{ item, index }">
            <a-list-item>
              <template #actions>
                <div class="actions">
                <span
                    v-if="item.noticeStatus === '0'"
                    class="link underline"
                    @click.stop="isRead(item, index)"
                    v-auth="['system:notice:read']"
                >标为已读</span
                >
                  <span
                      v-else
                      class="link underline"
                      @click.stop="delectemail(item, index)"
                      v-auth="['system:notice:delete']"
                  >删除</span
                  >
                  <div>
                    <span class="create-time">{{ format(item.createTime) }}</span>
                  </div>
                </div>
              </template>
              <a-list-item-meta @click="handleGoToDetail(item)">
                <template #title>
                <span
                    class="circle"
                    :style="`color: ${ item.noticeStatus === '0' ? '#DC5E4F' : '#C4C4C4'};font-size: 20px;`"
                >●</span
                >
                  <span class="noticeTitle">{{ item.noticeTitle }}</span>
                </template>
                <template #description>
                  <div class="noticeDetail">
                    <span :title=item.noticeDetail>{{ item.noticeDetail }}</span>
                  </div>
                  <div class="noticeDetail">
                    <span :title=item.noticeDetail>{{ getMenuName(item.firstMenuName,item.secondMenuName) }}</span>
                  </div>
                </template>
              </a-list-item-meta>
            </a-list-item>
          </template>
        </a-list>
      </template>
      <a-list item-layout="horizontal" :data-source="item.childrenStaffNotices" id="notice-list">
        <template #renderItem="{ item, index }">
          <a-list-item>
            <template #actions>
              <div class="actions">
                <span
                    v-if="item.noticeStatus === '0'"
                    class="link underline"
                    @click.stop="isRead(item, index)"
                >标为已读</span
                >
                <span
                    v-else
                    class="link underline"
                    @click.stop="delectemail(item, index)"
                >删除</span
                >
                <div>
                  <span class="create-time">{{ format(item.createTime) }}</span>
                </div>
              </div>
            </template>
            <a-list-item-meta @click="handleGoToDetail(item)">
              <template #title>
                <span
                    class="circle"
                    :style="`color: ${ item.noticeStatus === '0' ? '#DC5E4F' : '#C4C4C4'};font-size: 20px;`"
                >●</span
                >
                <span class="noticeTitle">{{ item.noticeTitle }}</span>
              </template>
              <template #description>
                <div class="noticeDetail">
                  <span :title=item.noticeDetail>{{ item.noticeDetail }}</span>
                </div>
                <div class="noticeDetail">
                  <span :title=item.noticeDetail>{{ getMenuName(item.firstMenuName,item.secondMenuName) }}</span>
                </div>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </a-collapse-panel>
  </a-collapse>

</template>

<script>
import {defineComponent, ref} from "vue";
import {useGlobalPropertyHook} from "@/hooks/common.js";
import {message} from "ant-design-vue";
import "@/assets/Notice/envelopeClose/iconfont.css";
import "@/assets/Notice/envelopeOpen/iconfont.css";
import {useStore} from "vuex";
import moment from "moment";
import { Empty } from 'ant-design-vue';
import {computed} from "@vue/runtime-core";
import {formatMenuName} from "@/utils/common"

export default defineComponent({
  props: {
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    },
    isShowCheckBox: Boolean,
  },
  emits: ["updata-notice-list", "change-count-number", "handle-to-detail"],
  setup(props, {emit}) {
    // const {dataList} = toRefs(props);
    const dataSourceList = computed(() => props.dataList);
    const {$api} = useGlobalPropertyHook();
    const store = useStore();
    const delectemail = (notice) => {
      let getNotice = [];
      getNotice.push(notice);
      if (notice.noticeStatus == 0) {
        message.warn("您还没读这个消息呢，不能删除哦");
      } else {
        $api.delectNotice(getNotice).then(() => {
          // dataSourceList.value.splice(id, 1);
          message.success("删除成功");
          emit("updata-notice-list");
        });
      }
    };

    const isRead = (notice) => {
      let getSingleNotice = [];
      getSingleNotice.push(notice);
      if (notice.noticeStatus == 1) return;

      if (notice?.childrenStaffNotices?.length > 0) {
        $api.postReadParentNotice(getSingleNotice).then((res) => {
          if (res.code == 200) {
            // dataList.value[id].noticeStatus = 1;
            message.success("已读");
            emit("updata-notice-list");
            store.dispatch("getCountNum");
          }
        }
        );
      } else {
        $api.readSingleNoticeDone(getSingleNotice).then((res) => {
          if (res.code == 200) {
            // dataList.value[id].noticeStatus = 1;
            message.success("已读");
            emit("updata-notice-list");
            store.dispatch("getCountNum");
          }
        });
      }
    };

    const format = (time) => {
      return moment(time).format("YYYY.MM.DD HH:mm:ss");
    };
    const getMenuName = (firstName, secondName) => {
      return formatMenuName(firstName, secondName)
    }
    const activeKey = ref([]);
    const handleGoToDetail = (item) => {
      emit('handle-to-detail', item)
    }
    const resetActiveKey = () => {
      activeKey.value = []
    }

    return {
      delectemail,
      isRead,
      format,
      handleGoToDetail,
      activeKey,
      getMenuName,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      dataSourceList,
      resetActiveKey,
    };
  },
});
</script>

<style lang="less" scoped>
.noticeTitle {
  margin-left: 8px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}

.noticeDetail {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #999999;
}

.notice-empty {
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.actions {
  //position: absolute;
  //top: -5px;
  //right: 0;
}

.create-time {
  margin-top: 12px;
  color: #ccc;
  float: right;
}

:deep(.ant-collapse-header),
:deep(.ant-collapse-content) .ant-list-item{

  &:hover {
    background: #e9f6fe;
  }
}

.icon {
  color: #0089ff;
  font-size: 15px;
}

.iconMail {
  font-size: 20px;
  margin-top: 8px;
  margin-right: 16px;
}

.delectIcon {
  font-size: 18px;
}

.avatar {
  display: flex;
}

:deep(.ant-list-item-meta-content) {
  padding-right: 3%;
}
</style>
