const enumList = {
  // 布尔类型:
  tagOps: [
    {
      value: 0,
      label: "否",
    },
    {
      value: 1,
      label: "是",
    },
  ],
  // 季度状态
  quarterOps: [
    {
      value: 'Q1',
      label: 'Q1'
    },
    {
      value: 'Q2',
      label: 'Q2'},
    {
      value: 'Q3',
      label: 'Q3'
    },
    {
      value: 'Q4',
      label: 'Q4'
    }
    ],
  noticeStatusOps : [
    {
      label: "未读",
      value: 0,
    },
    {
      label: "已读",
      value: 1,
    }
  ],

}
export { enumList }