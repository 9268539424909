<template>
  <section class="app-container">
    <Filter @search="doSearch" ref="filterRef" @allEamilRead="allEamilRead"></Filter>
    <div class="noticeContent">
      <email-list ref="emailListRef" :dataList="dataList" @handle-to-detail="handleGoToDetail" @updataNoticeList="handleSearch"/>
    </div>
  </section>
  <notice-modal
    v-model:modalVisible="modalVisible"
    @sumbit-modal="batchDelect"
    @close-modal="closeModalVisible"
    sumbitBtn="确定"
    cancelBtn="取消"
  >
    <div class="content">是否确定删除全部通知?</div>
  </notice-modal>
</template>

<script>
import { defineComponent, onActivated, ref, watch } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import EmailList from "./component/EmailList.vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import NoticeModal from "@/components/NoticeModal.vue";
import {useRoute, useRouter} from "vue-router";
import Filter from "./component/Filter.vue";
import { getNoticeTargetPage } from "@/utils/common"

export default defineComponent({
  name: "notice",
  components: {
    NoticeModal,
    Filter,
    EmailList,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();
    const current = ref(1);
    const pageSize = ref(10);
    const activeKey = ref("");
    const dataList = ref([]);
    const total = ref();
    const emailList = ref(null);
    const modalVisible = ref(false);
    const pageSizeOptions = ref(["10", "20", "30", "40", "50"]);
    // let oldActiveKey = "";
    const batchEdit = () => {
      if (dataList.value.length == 0) {
        message.warning("无需此操作");
      } else {
        modalVisible.value = true;
      }
    };

    const allEamilRead = async () => {
      $api.readAll().then(() => {
        message.success("操作成功");
        // tabChange();
        handleSearch()
        store.dispatch("getCountNum");
      });
    };

    const showSizeChange = (page, size) => {
      current.value = page > 0 ? page : 1;
      pageSize.value = size;
      // tabChange();
      handleSearch()
    };

    const change = (page, size) => {
      current.value = page > 0 ? page : 1;
      pageSize.value = size;
      // tabChange();
      handleSearch()
      // 回到页面顶端
      document.getElementById("notice-list").scrollIntoView()
    };

    const batchDelect = async () => {
      if (dataList.value.some((item) => item.noticeStatus == "0")) {
        message.warning("消息已经全部删除，无需此操作！");
      } else {
        $api.delectAllNotice(activeKey.value).then((res) => {
          if (res.code == 200) {
            closeModalVisible();
            store.dispatch("getCountNum");
            message.success("全部删除成功");
            // tabChange();
            handleSearch()
          }
        });
      }
    };

    const closeModalVisible = () => {
      modalVisible.value = false;
    };

    const doSearch = async (states) => {
      const { data } = await $api.getNoticesList(states)
      dataList.value = data
    }

    const handleSearch = () => {
      filterRef.value.doSearch()
    }

    const route = useRoute();

    const router = useRouter();
    const handleGoToDetail = (noticeInfo) => {
      // 批量不跳转
      if(noticeInfo.relationStatus == "6") return
      const {relationStatus, firstMenu, secondMenu} = noticeInfo
      // 如果没有一二级菜单则是旧数据，不需要跳转
      if(!firstMenu || !secondMenu) return
      const info = Object.assign({}, {relationStatus, firstMenu, secondMenu})
      router.push({
        path: getNoticeTargetPage(info),
        query: { id: noticeInfo.relationId },
      });
    }
    const filterRef = ref()
    const emailListRef = ref()


    onActivated(() => {
      // tabChange();
      // getFilterOptions();
      handleSearch();
      emailListRef.value.resetActiveKey()
    });

    // 每次点击<查看更多>按钮都重新查询消息通知
    watch(
        ()=>route.query.pageFlag,
        () => {
          handleSearch()
        }
    )
    return {
      dataList,
      current,
      pageSizeOptions,
      batchEdit,
      pageSize,
      // tabChange,
      activeKey,
      total,
      allEamilRead,
      emailList,
      batchDelect,
      showSizeChange,
      change,
      modalVisible,
      closeModalVisible,
      handleGoToDetail,
      doSearch,
      filterRef,
      handleSearch,
      emailListRef,
    };
  },
});
</script>

<style lang="less" scoped>
.app-container {
  .noticeContent {
    height: calc(100% - 100px);
    overflow: auto;
  }

  .paginationBottom {
    background: #fff;
    padding: 8px 10px;
    position: absolute;
    bottom: 0;
  }
}
</style>
