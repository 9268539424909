<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout @doReset="resetForm"
                    @doSearch="doSearch"
                    ref="filterLayoutRef">
        <template #customform>
          <a-form
              ref="formRef"
              class="form rms-form"
              layout="vertical"
              :model="formState"
          >
            <a-form-item label="一级菜单" name="firstMenus" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.firstMenus"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="3"
                  :options="filterOptions.firstMenuOpt"
                  @change="handleFilterSecondMenu"
              >
              </SelectWithAll>
            </a-form-item>

            <a-form-item
                label="二级菜单"
                name="secondMenus"
                class="rms-form-item"
            >
              <SelectWithAll
                  v-model:value="formState.secondMenus"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="3"
                  :options="filterOptions.secondMenuOpt"
              >
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="标题类型" name="noticeTitles" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.noticeTitles"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="3"
                  :options="filterOptions.noticeTitlesOpt"
              >
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="消息状态" name="noticeStatus" class="rms-form-item">
              <a-select
                  class="item"
                  v-model:value="formState.noticeStatus"
                  placeholder="请选择"
                  showArrow
                  allowClear
                  :options="filterOptions.noticeStatusOpt"
              >
              </a-select>
            </a-form-item>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
            <a-button
                type="primary"
                ghost
                @click="allEamilRead"
                v-auth="['system:notice:readAll']"
            >全部标记已读</a-button
            >
          </div>
        </template>
      </FilterLayout>
    </div>
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, nextTick } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import {cloneDeep} from "lodash";
import {enumList} from "@/api/enumStatic";
import {dictionaryOptionsCode} from "@/utils/common";

export default defineComponent({
  name: "TopFilter",
  components: {},
  props: {},
  emits: ["search","allEamilRead"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const { $EventBus } = useGlobalPropertyHook();
    const formRef = ref();
    const roleList = ref([]);
    const costCtrOps = ref([]);
    const notUsedRole = ["Operation", "PM", "Consultant", "LM", "Admin"];

    const getRolePermListData = () => {
      $api.getRoleInfo().then((res) => {
        roleList.value = res.data.filter(
            (item) => !notUsedRole.includes(item.roleKey)
        );
      });
    };

    const resetForm = () => {
      Object.assign(filterOptions, initialFilterOptions)
      formRef.value.resetFields();
      updateParams()
      ctx.emit("search", currentState);
    };
    let currentState  = {}
    const updateParams =() => {
      currentState = cloneDeep(formState);
    }
    const formState = reactive({
      firstMenus: [],
      secondMenus: [],
      noticeTitles: [],
      noticeStatus: undefined,
    });
    const doSearch = () => {
      updateParams()
      currentState.noticeStatus = typeof currentState.noticeStatus === 'number' ? [currentState.noticeStatus] : []
      ctx.emit("search", currentState);
    };
    const getCtrOptions = () => {
      $EventBus.on("getCtrOps", (ctrOps) => {
        costCtrOps.value = ctrOps;
      });
    };
    const filterOption = (input, option) => {
      return (
          option.children[0].children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
      );
    };

    const filterOptions = reactive({
      firstMenuOpt: [],
      secondMenuOpt: [],
      noticeTitlesOpt: [],
      noticeStatusOpt: enumList.noticeStatusOps
    })

    let initialFilterOptions = {}
    const getMenusFromAllData = async () => {
      const { data } = await $api.getMenusFromAllData()
      const { dictTypeList, firstMenuList, secondMenuList } = data
      filterOptions.firstMenuOpt = dictionaryOptionsCode(firstMenuList)
      filterOptions.secondMenuOpt = dictionaryOptionsCode(secondMenuList)
      filterOptions.noticeTitlesOpt = dictTypeList.map(item => {
        return {label: item, value: item}})
      initialFilterOptions = cloneDeep(filterOptions)
    }

    const allMenuRelation = ref([])
    const getMenusOptRelation = async () => {
      const { data } = await $api.getMenusRelation()
      allMenuRelation.value = data
    }
    const handleFilterSecondMenu = () => {
      formState.secondMenus.splice(0)
      if(formState.firstMenus.length <1) {
        Object.assign(filterOptions, initialFilterOptions)
        return
      }
      const temp = {}
      formState.firstMenus.forEach(item => temp[item] = true)
      filterOptions.secondMenuOpt = dictionaryOptionsCode(allMenuRelation.value.filter(item => temp[item.firstCode]))
    }
    const allEamilRead = () => {
      ctx.emit('allEamilRead')
    }
    onMounted(() => {
      getRolePermListData();
      nextTick(doSearch);
      getCtrOptions();
      getMenusOptRelation();
      getMenusFromAllData();
    });

    return {
      formState,
      formRef,
      doSearch,
      resetForm,
      getRolePermListData,
      roleList,
      costCtrOps,
      filterOption,
      updateParams,
      allMenuRelation,
      handleFilterSecondMenu,
      filterOptions,
      allEamilRead
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;

  .ant-select {
    text-align: left;
  }
}
</style>
